@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'IBMPlexSans';
  src: url('/src/fonts/IBMPlexSans-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Catalogue';
  src: url('/src/fonts/Catalogue-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BelledeMaiItalic';
  src: url('/src/fonts/BelledeMaiItalic-Semibold.otf') format('opentype');
  font-weight: 600; /* Используй соответствующее значение для Semibold */
  font-style: italic;
}

html {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-smoothing: subpixel-antialiased;
}

body {
  margin: 0;
  font-family: 'IBMPlexSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1c1c;
}

code {
  font-family: 'IBMPlexSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}


